import { Edition } from "../Edition";
import { Library } from "../Library";

export function library() {
  return {
    init() {
      try {
        this.checkIfAuthed();
        this.revitalize();
      } catch (e) {
        this.view = "login";
      }
    },
    library: this.$persist(new Library()),
    view: "library",
    currentEditionId: null,
    currentEdition: null,
    refreshInProgress: false,
    openEdition(id) {
      this.currentEditionId = id;
      this.currentEdition = this.library.editions.find(
        (edition) => id === edition.id
      );
      this.view = "edition";
      this.currentEdition.new = false;
    },
    openLibrary() {
      this.view = "library";
    },
    openOrDownloadEdition(id) {
      const edition = this.library.editions.find(
        (edition) => id === edition.id
      );
      if (edition.localEditionUrl) {
        this.openEdition(id);
      } else {
        edition.download();
      }
    },
    refresh() {
      this.refreshInProgress = true;
      this.library
        .syncEditions()
        .then(() => {
          this.refreshInProgress = false;
        })
        .catch(() => {
          this.view = "login";
        });
    },
    revitalize() {
      if (this.library.constructor.name !== "Library") {
        this.library = Object.assign(new Library(), this.library);
        this.library.editions.forEach((edition, index) => {
          this.library.editions[index] = Object.assign(
            new Edition(null),
            edition
          );
          this.library.editions[index].validateCache();
        });
      }
    },
    checkIfAuthed(): boolean {
      const auth = localStorage.getItem("auth");
      if (typeof auth == "undefined" || auth === null) {
        throw new Error("No Auth safed");
      } else {
        return true;
      }
    },
    login() {
      const authString = this.base64Encode(
        this.form.username + ":" + this.form.password
      );
      localStorage.setItem("auth", authString);
      this.view = "library";
      this.revitalize();
    },
    form: {
      username: "",
      password: "",
    },

    base64Encode(str) {
      const utf8Bytes = encodeURIComponent(str).replace(
        /%([0-9A-F]{2})/g,
        function (match, p1) {
          return String.fromCharCode("0x" + p1);
        }
      );
      return btoa(utf8Bytes);
    },
  };
}
