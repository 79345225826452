import { Edition } from "./Edition";
import { Week } from "./Week";

export class Library {
  editions: Edition[] = [];

  weeks: Week[] = [];

  constructor() {
    this.syncEditions();
  }

  async syncEditions() {
    let response = await fetch(
      "https://paper-api.philippspeck.workers.dev/editions?auth=" +
      localStorage.getItem("auth")
    );
    if (response.status == 401) {
      localStorage.removeItem("auth");
      throw new Error("unauthed");
    }
    const data = await response.json();
    data.forEach((edition) => {
      if (!this.editionExistsLocally(edition.rowid)) {
        this.editions.push(new Edition(edition));
      }
    });
    this.orderEditions();
  }

  editionExistsLocally(editionId: number): boolean {
    return (
      typeof this.editions.find((edition) => editionId === edition.id) !==
      "undefined"
    );
  }

  orderEditions() {
    this.editions.sort((a, b) => {
      if ((new Date(a.publishingDate)) > (new Date(b.publishingDate))) {
        return -1;
      } else if (a.publishingDate < b.publishingDate) {
        return 1;
      } else {
        return 0;
      }
    });
    this.sortEditionsWithCalendarWeeks();
  }

  sortEditionsWithCalendarWeeks(): void {
    this.weeks = [];

    this.editions.forEach((edition) => {
      let publishingDate = new Date(edition.publishingDate);
      let publishingDateYear = publishingDate.getUTCFullYear();
      let publishingDateWeek = this.getWeekNumber(publishingDate);
      let week = this.getOrCreateWeek(publishingDateYear, publishingDateWeek);

      week.editions.push(edition);
    });
  }

  getOrCreateWeek(year: number, week: number): Week {
    let foundWeek = this.weeks.find((weekOfArray: Week) => {
      return weekOfArray.year == year && weekOfArray.week == week;
    });

    if (typeof foundWeek !== "undefined") {
      return foundWeek;
    } else {
      let newWeek = new Week(year, week);
      this.weeks.push(newWeek);
      return newWeek;
    }
  }

  getWeekNumber(d: Date): number {
    let startDate = new Date(d.getFullYear(), 0, 1);
    let days = Math.floor((d - startDate) / (24 * 60 * 60 * 1000));
    let weekNumber = Math.ceil(days / 7);
    return weekNumber;
  }
}
