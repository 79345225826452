import Alpine from "alpinejs";
import persist from "@alpinejs/persist";
import { library } from "./alpine/library";

Alpine.plugin(persist);

window.Alpine = Alpine;

document.addEventListener("alpine:init", () => {
  Alpine.data("library", library);
});

Alpine.start();

const registerServiceWorker = async () => {
  if ("serviceWorker" in navigator) {
    try {
      const registration = await navigator.serviceWorker.register("/sw.js", {
        scope: "/",
      });
      if (registration.installing) {
        console.log("Service worker installing");
      } else if (registration.waiting) {
        console.log("Service worker installed");
      } else if (registration.active) {
        console.log("Service worker active");
      }
    } catch (error) {
      console.error(`Registration failed with ${error}`);
    }
  }
};

// …

registerServiceWorker();
