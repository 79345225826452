import { Edition } from "./Edition";

export class Week {
  year: number;
  week: number;
  editions: Edition[] = [];

  constructor(year: number, week: number) {
    this.year = year;
    this.week = week;
  }
}
