import { EditionNotFoundInCache } from "./Errors/EditionNotFoundInCache";

export class Edition {
  id: number;
  name: string;
  paperName: string;
  editionPath: string;
  editionCoverPath: string;
  editionCoverUrl: string;
  publishingDate: Date;
  created: Date;
  new: boolean;
  localEditionUrl: string | null = null;
  downloadInProgress: boolean = false;

  constructor(data: any) {
    if (data !== null) {
      this.id = data.rowid;
      this.name = data.name;
      this.paperName = data.paperName;
      this.editionPath = data.editionPath;
      this.editionCoverPath = data.editionCoverPath;
      this.publishingDate = new Date(data.publishingDate);
      this.created = new Date(data.created);
      this.new = true;
      this.setValues();
    }
  }

  async setValues() {
    this.editionCoverUrl =
      "https://paper-api.philippspeck.workers.dev/editionpdf?document=" +
      this.editionCoverPath +
      "&auth=" +
      localStorage.getItem("auth");
  }

  async edtitionCacheUrl(): Promise<string> {
    const cache = await caches.open("edition-files");
    const cacheKeys = await cache.keys();
    for (const key of cacheKeys) {
      const path = new URL(key.url).pathname;
      if (path == "/cache/" + this.editionPath) {
        return key.url;
      }
    }
    throw new EditionNotFoundInCache(`Edition ${this.id} not found`);
  }

  async validateCache() {
    this.edtitionCacheUrl()
      .then((result) => {
        console.log(result);
        this.localEditionUrl = result;
      })
      .catch(() => {
        this.localEditionUrl = null;
      });
  }

  async download() {
    this.downloadInProgress = true;
    try {
      const cache = await caches.open("edition-files");

      const editionFile = await fetch(
        "https://paper-api.philippspeck.workers.dev/editionpdf?document=" +
          this.editionPath +
          "&auth=" +
          localStorage.getItem("auth")
      );
      if (editionFile.status == 401) {
        localStorage.removeItem("auth");
        location.reload();
        return;
      }
      await cache.put("/cache/" + this.editionPath, editionFile);
      this.localEditionUrl = "/cache/" + this.editionPath;
    } catch (e) {
      console.log(e);
    } finally {
      this.downloadInProgress = false;
    }
  }
}
